import autofocus from "vue-autofocus-directive";
import Buefy from "buefy"; // Leave this, it makes Buefy work everywhere
import Vue2Filters from 'vue2-filters';
import format from 'date-fns/format';
import {enGB, enUS, enCA, enIN, } from 'date-fns/locale';
import cdnHelper from "~/mixins/cdn-helper";


//Register global attributes to be present in all vue components
Vue.prototype.isLoggedIn = loggedIn;
Vue.prototype.cdnBase = cdnBase;

const dateFormatLocales = {
	"en_US": enUS,
	"en_GB": enGB,
	"en_IN": enIN,
	"en_CA": enCA,
};

Vue.use(Vue2Filters);

// CDN url helper mixin
Vue.mixin(cdnHelper);

// Configure Buefy
Vue.prototype["$buefy"].config.setOptions({
	defaultIconPack: "fal",
	customIconPacks: {
		"fas": {
			sizes: {
				"default": "",
				"is-small": "fa-xs",
				"is-medium": "fa-lg",
				"is-large": "fa-2x"
			}
		},
		"fal": {
			sizes: {
				"default": "",
				"is-small": "fa-xs",
				"is-medium": "fa-lg",
				"is-large": "fa-2x"
			}
		},
		"far": {
			sizes: {
				"default": "",
				"is-small": "fa-xs",
				"is-medium": "fa-lg",
				"is-large": "fa-2x"
			}
		}
	}
});

/*
	Groups like pages so that we can obtain overall performance metrics for the group
	rather than for each unique URL
 */
const pathPrefixesToGroup = [
	'/content/blocks/',
	'/health-professionals/',
	'/products/dp/',
	'/products/by-ingredient/',
	'/quizzes/',
	'/take-5-daily/article/',
	'/take-5-daily/video/',
	'/take-5-daily/podcast/',
	'/u/'
]
function sanitizeUrlPath() {
	let matchIx = pathPrefixesToGroup.findIndex(prefix => location.pathname.startsWith(prefix))
	return matchIx >= 0 ? `${pathPrefixesToGroup[matchIx]}*` : location.pathname
}

//Set some axios defaults
axios.defaults.headers.common = {
	"Content-Type": "application/json",
	"X-Requested-With": "XMLHttpRequest",
	"X-CSRF-TOKEN": document.querySelector("meta[name='_csrf']").getAttribute("content")
};

// Create floating label directive
Vue.directive("floating-label", {
    inserted: function (el) {
        // Add a class for styling
        el.classList.add("has-floating-label");

        // Move the label after the input (so we can do pure CSS floating label styling)
        const label = el.querySelector("label");
        let input = el.querySelector("input");
        if (!input) {
        	input = el.querySelector("textarea");
        }
        if (!input) {
        	input = el.querySelector("select");
        }

        if (label && input) {
        	input.parentElement.insertBefore(label, input.nextSibling);

        	// Because I'm lazy and don't want to have to set the invisible placeholder all the time, copy the label into the placeholder
        	input.placeholder = label.textContent;
        }

    }
});

//Add a filter allowing to format the currency based on locale
Vue.filter("toCurrency", function (value) {
	let result = value;
    if (!isNaN(parseFloat(value))) {
    	let loc = pageLocale.replace("_", "-");
    	var formatter = new Intl.NumberFormat(loc, {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0
        });
        result = formatter.format(value);
    }
    return result;
});

//Add a filter allowing to format a date based on locale and/or format
Vue.filter("dateFormat", function (value, defaultResult, dtFormat) {
	let result = defaultResult;
	if (value) {
		let tmpDate = new Date(value);
		if (tmpDate !== "Invalid Date" && !isNaN(tmpDate)) {
			let loc = pageLocale.replace("_", "-");
			const dateFnsLocale = dateFormatLocales[pageLocale];
	    	if (dtFormat && dateFnsLocale) {
				result = format(tmpDate, dtFormat, {locale: enUS});
	    	} else {
	    		result = tmpDate.toLocaleDateString(loc);
	    	}
		}
	}
    return result;
});

/*
* This component is useful for rendering inline html, without including a wrapper element.
* For example, say we had the following html content:
*
* <span>Some cool stuff here</span>
*
*
* Using the following would insert the div into the DOM as well:
*
* <div v-html="content"></div>
* ... becomes ...
* <div><span>Some cool stuff here</span></div>
*
*
* This component allows us to include the html content without a wrapper:
*
* <html-fragment :html="content"></html-fragment>
* ... becomes ...
* <span>Some cool stuff here</span>
*
*
*/
Vue.component('html-fragment', {
	functional: true,
	props: ['html'],
	render(h, ctx) {
		if (ctx.props.html) {
			const html = ctx.props.html.trim();
			const nodes = new Vue({
				beforeCreate() {
					this.$createElement = h
				},
				template: `<div>${html}</div>`
			}).$mount()._vnode.children;
			return nodes;
		}
	}
});

// Global components
Vue.directive("autofocus", autofocus);